import { createContext, useReducer } from "react";

const AppContext = createContext();

const initialState = {
    template: null,
    booklets: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_TEMPLATE": {
            const booklet = action.payload;
            transform(booklet.content);

            return {
                ...state,
                template: booklet,
            };
        }

        case "SET_BOOKLETS": {
            return {
                ...state,
                booklets: action.payload,
            };
        }

        case "ADD_BOOKLET": {
            const booklets = [...state.booklets];
            booklets.push(action.payload);

            return {
                ...state,
                booklets,
            };
        }

        case "UPDATE_BOOKLET": {
            const booklets = [...state.booklets];
            const booklet = booklets.find((x) => x.id === action.payload.id);
            booklet.name = action.payload.name;
            booklet.description = action.payload.description;

            return {
                ...state,
                booklets,
            };
        }

        case "REMOVE_BOOKLET": {
            return {
                ...state,
                booklets: state.booklets.filter((x) => x.id !== action.payload),
            };
        }

        default: {
            console.error(`Invalid action '${action.type}' supplied to AppContext.`);
            return;
        }
    }
};

const transform = (booklet) => {
    for (let i = 0, len = booklet.length; i < len; i++) {
        const entry = booklet[i];

        if (entry.code) {
            // Type
            entry.id = entry.code;
            entry.originId = entry.code;
            entry.orgCode = entry.code;
            entry.isProcedure = false;
        } else {
            // Procedure
            entry.orgId = entry.id;
            entry.originId = entry.id;
            entry.isProcedure = true;
        }

        if (entry.children) transform(entry.children);
    }
};

const AppContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ state, dispatch }}>{props.children}</AppContext.Provider>;
};

export { AppContextProvider, AppContext };
