export const production = {
    api: "https://wtcb-tools-production-maintenance-booklet.azurewebsites.net/",
    sentry: {
        dsn: "https://3329ad27fa3749928c81a820b83d1eb6@o399431.ingest.sentry.io/5940622",
        env: "production",
        sampleRate: 0.1,
    },
    gtm: {
        gtmId: "GTM-K74ZHMB",
    },
    auth: {
        clientId: "68db96b4-e340-4a6b-adac-ea393079800e",
        authority: "https://login.bbri.be/wtcbidence.onmicrosoft.com/b2c_1a_signup_signin/",
        knownAuthorities: ["https://login.bbri.be/"],
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.hostname}`,
        cacheLocation: "localStorage",
        scopes: ["https://wtcbidence.onmicrosoft.com/BIMio/BIMio.Access", "openid", "profile", "offline_access"],
    },
    feedback: "https://toolsmodels.azurewebsites.net/api/v1/Feedback",
};
