import React, { useState, useEffect } from "react";
import { previewBooklet } from "../../adapters/BookletsAdapter";
import { Spinner } from "@buildwise/ui";

import styles from "./PdfPreview.module.css";

const PdfPreview = ({ booklet }) => {
    const [pdf, setPdf] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (booklet && booklet.id) {
            setIsLoading(true);
            previewBooklet(booklet.id, booklet.language ?? "").then((url) => {
                setPdf({ url: url });
                setIsLoading(false);
            });
        }
    }, [booklet]);

    return (
        <div className={styles.root}>
            {isLoading ? <Spinner /> : <embed src={pdf.url} type="application/pdf" height={800} width="100%" />}
        </div>
    );
};

export default PdfPreview;
