import { useContext, useEffect, useRef, useState } from "react";
import { Link, matchPath, Outlet } from "react-router-dom";
import { useParams, useNavigate, useLocation, generatePath } from "react-router";

import { Layers, Plus } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/pro-duotone-svg-icons";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import { ApplicationMenuItem, ApplicationTitle, Breadcrumb, Header, Page, useAuthentication, useLanguage, usePrismic } from "@buildwise/ui";

import { AppContext } from "./context/AppContextProvider";

import { config } from "./_configuration/configuration";

import logo_nl from "./assets/logo_nl.svg";
import logo_fr from "./assets/logo_fr.svg";
import "./assets/global.css";

const App = () => {
    const { state } = useContext(AppContext);
    const [bookletId, setBookletId] = useState(null);
    const navigate = useNavigate();
    const params = useParams();
    const { isAuthenticated, isAdmin, login } = useAuthentication();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);
    const location = useLocation();
    const [routesMap, setRoutesMap] = useState(null);
    const { language: locale, setLanguage } = useLanguage();
    const timeoutRef = useRef();
    const routeLanguageRef = useRef();

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        const map = { routes: {}, languages: {} };
        for (let i = 0; i < Object.keys(config.routes).length; i++) {
            const key = Object.keys(config.routes)[i];
            const route = config.routes[key];
            map.routes[route["nl-be"]] = route["fr-be"];
            map.routes[route["fr-be"]] = route["nl-be"];
            if (!map.languages[route["nl-be"]]) map.languages[route["nl-be"]] = [];
            map.languages[route["nl-be"]].push("nl-be");
            if (!map.languages[route["fr-be"]]) map.languages[route["fr-be"]] = [];
            map.languages[route["fr-be"]].push("fr-be");
        }
        setRoutesMap(map);
    }, []);

    useEffect(() => {
        if (!Boolean(params.lang)) {
            const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
            window.localStorage.setItem("locale", storedLanguage);
            setLanguage(storedLanguage);
            navigate(`/${locale.split("-")[0]}`);
            routeLanguageRef.current = locale.split("-")[0];
            return;
        }
    }, [params]);

    useEffect(() => {
        if (!params.lang) return;
        if (routeLanguageRef.current === params.lang) return;
        const storedLanguage = window.localStorage.getItem("locale") ?? "nl-be";
        const currentRoute = getRoutePath(location, params);
        const targetLanguage = `${params.lang}-be`;
        if (["nl-be", "fr-be"].indexOf(targetLanguage) === -1) {
            navigate(generatePath(currentRoute, { ...params, lang: storedLanguage.split("-")[0] }));
            return;
        }
        routeLanguageRef.current = params.lang;
        setLanguage(targetLanguage);
    }, [params, routesMap]);

    useEffect(() => {
        if (!routesMap) return;
        const targetLang = getLanguage(locale);
        const currentRoute = getRoutePath(location, params);
        let target;
        if (currentRoute === "/") {
            target = `/${targetLang}`;
        } else {
            let targetRoute = currentRoute;
            const currentRouteLang = routesMap.languages[currentRoute];
            if (!currentRouteLang.includes(locale)) targetRoute = routesMap.routes[currentRoute];
            target = generatePath(targetRoute, { ...params, lang: targetLang });
        }
        window.localStorage.setItem("locale", locale);
        navigate(target);
    }, [locale, routesMap]);

    useEffect(() => {
        const bookletId = matchPath(
            {
                path: config.routes.booklet[language],
            },
            location.pathname
        )?.params?.id;
        if (bookletId && bookletId !== "nieuw" && bookletId !== "nouveau") setBookletId(bookletId);
    }, [params]);

    useEffect(() => {
        if (isAuthenticated && bookletId && state.booklets.length > 0) {
            const booklet = state.booklets.find((x) => Number(x.id) === Number(bookletId));
            if (!booklet) setBookletId(null);
        }
    }, [state.booklets]);

    useEffect(() => {
        if (isAuthenticated) {
            clearTimeout(timeoutRef.current);
        } else if (bookletId) {
            timeoutRef.current = setTimeout(
                () =>
                    login(
                        null,
                        () => navigate("/"),
                        () => navigate("/")
                    ),
                1000
            );
        }
    }, [bookletId, isAuthenticated]);

    const logo = language === "nl-be" ? logo_nl : logo_fr;

    return (
        <>
            <Helmet>
                <html lang={language.split("-")[0]}></html>
                <title>{document?.data?.application_title ?? document?.data["booklets_booklet"]}</title>
            </Helmet>

            <Page feedback>
                <Header homeAction={() => navigate("/")}>
                    <ApplicationTitle icon={<img src={logo} alt={document?.data["booklets_booklet"]} />}></ApplicationTitle>
                    <ApplicationMenuItem
                        id="app-nav-my-booklets"
                        icon={<Layers />}
                        label={document?.data["booklets_my_booklets"]}
                        onClick={() => (isAuthenticated ? navigate(generatePath(config.routes.landing[language], { ...params })) : null)}
                        className={isAuthenticated ? null : "transparent"}
                        data-for={"tooltip"}
                        data-tip={document?.data["booklets_user_feature"]}
                        data-tip-disable={isAuthenticated}
                    />
                    <ApplicationMenuItem
                        id="app-nav-new-booklet"
                        icon={<Plus />}
                        label={document?.data["booklets_new_booklet"]}
                        onClick={() => {
                            navigate(generatePath(config.routes.newBooklet[language], { ...params }));
                        }}
                    />
                    <ApplicationMenuItem
                        id="app-nav-admin"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        label={document?.data["booklets_beheer"]}
                        onClick={() => navigate(generatePath(config.routes.admin[language], { ...params }))}
                        style={isAdmin ? {} : { display: "none" }}
                    />
                </Header>

                <Breadcrumb>
                    <Link id="breadcrumb-app-home" to="/">
                        {isAuthenticated ? document?.data["booklets_my_booklets"] : document?.data["booklets_booklet"]}
                    </Link>
                </Breadcrumb>

                <Outlet />

                <ReactTooltip
                    id={"tooltip"}
                    type="light"
                    effect="solid"
                    border={true}
                    borderColor={"#8c969b"}
                    place={"top"}
                    getContent={(dataTip) => <span>{dataTip}</span>}
                />
            </Page>
        </>
    );
};

const getLanguage = (locale) => {
    return locale.split("-")[0];
};

const getRoutePath = (location, params) => {
    const { pathname } = location;
    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }
    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

export default App;
