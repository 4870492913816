import { useEffect, useRef, useState } from "react";
import {
    Box,
    BoxBody,
    BoxTitle,
    Breadcrumb,
    Button,
    Content,
    ContentBody,
    ContentHeader,
    Panel,
    PanelBody,
    Select,
    Spinner,
    usePrismic
} from "@buildwise/ui";

import { getTemplateBookletById, getTemplateBooklets } from "../../adapters/BookletsAdapter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-light-svg-icons";
import Tree from "../../components/Tree/Tree";

import styles from "./Manage.module.css";
import { config } from "../../_configuration/configuration";
import { PrismicRichText } from "@prismicio/react";

const Manage = () => {
    const [list, setList] = useState([]);
    const [activePreview, setActivePreview] = useState();
    const [previewLanguage, setPreviewLanguage] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const inputFileRef = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    const previewLanguages = [
        { value: "Dutch", label: document?.data["globals_dutch"] },
        { value: "French", label: document?.data["globals_french"] },
    ];

    useEffect(() => {
        getTemplateBooklets().then(handleTemplates);
        setPreviewLanguage([previewLanguages[0]]);
    }, []);

    const handleTemplates = (templates) => {
        templates.forEach((x) => (x.createdAt = new Date(x.creationDate)));
        templates.sort((a, b) => a.createdAt - b.createdAt);

        setList(templates);
    };

    const handleFileUpload = () => {
        const { files } = inputFileRef.current;

        setIsUploading(true);

        const endpoint = `${config.api}api/v1/StaticBooklets`;
        const formData = new FormData();
        formData.append("File", files[0]);

        fetch(endpoint, {
            method: "POST",
            body: formData,
        }).then((response) => {
            getTemplateBooklets().then(handleTemplates);
            setIsUploading(false);
        });

        inputFileRef.current.value = "";
    };

    const renderList = () => {
        return (
            <div className={styles.list}>
                <ul>
                    {list
                        .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
                        .map((template, index) => (
                            <li key={index} onClick={() => setActivePreview(template.id)}>
                                {template.createdAt.toLocaleString()} {index === 0 && <strong>[Gepubliceerd]</strong>}
                            </li>
                        ))}
                </ul>
            </div>
        );
    };

    return (
        <>
            <Breadcrumb>{document?.data["admin_manage"]}</Breadcrumb>
            <Content>
                <ContentHeader>
                    <h1>{document?.data["admin_manage_maintenance"]}</h1>
                </ContentHeader>
                <ContentBody>
                    <Panel>
                        <PanelBody>
                            <Box useAccent>
                                <BoxTitle className={styles.remark}>
                                    Nieuwe versie opladen
                                    <strong>
                                        <PrismicRichText
                                            field={ document?.data["admin_upload_desc"]}
                                        />
                                    </strong>
                                </BoxTitle>
                                <BoxBody className={styles.upload}>
                                    {isUploading ? (
                                        <Content>
                                            <p>{ document?.data["admin_upload_in_progress"] }</p>
                                            <Spinner style={{ marginTop: 10, marginBottom: 10 }} />
                                        </Content>
                                    ) : (
                                        <>
                                            <input
                                                id="file-input"
                                                type="file"
                                                accept=".xls, .xlsx"
                                                ref={inputFileRef}
                                                style={{ display: "none" }}
                                                onChange={() => handleFileUpload()}
                                            />
                                            <Button
                                                id="manage-new-version-select-button"
                                                startIcon={<FontAwesomeIcon icon={faUpload} />}
                                                onClick={() => inputFileRef.current.click()}
                                            >
                                                { document?.data["admin_select_new_version"] }

                                            </Button>
                                        </>
                                    )}
                                </BoxBody>
                            </Box>
                            <Box>
                                <div className={styles.versions}>
                                    <BoxTitle>Versies</BoxTitle>

                                    <Select
                                        className={styles.language}
                                        options={previewLanguages}
                                        selection={previewLanguage}
                                        onChangeSelection={(selection) => setPreviewLanguage(selection)}
                                    />
                                </div>
                                <BoxBody>
                                    <div className={styles.previous}>
                                        {renderList()}
                                        <BookletPreview version={activePreview} language={previewLanguage[0]?.value} />
                                    </div>
                                </BoxBody>
                            </Box>
                        </PanelBody>
                    </Panel>
                </ContentBody>
            </Content>
        </>
    );
};

export default Manage;

const BookletPreview = ({ version, language }) => {
    const [booklet, setBooklet] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (version) {
            setIsLoading(true);
            getTemplateBookletById(version).then((booklet) => {
                setBooklet(booklet.content);
                setIsLoading(false);
            });
        }
    }, [version]);

    const renderTreeItem = (item) => {
        if (item.description) {
            const sources = [];

            if (item.sources) {
                for (let i = 0; i < item.sources.length; i++) {
                    sources.push(item.sources[i].shortForm);
                }
            }

            return (
                <>
                    {item.description && item.description[language] && <p>{item.description[language]}</p>}
                    {item.duration && (
                        <div className={styles.group}>
                            <span className={styles.periodicity}>{document?.data["admin_periodicity"]}: </span>
                            <span>
                                {item.duration.minDuration > 0 ? item.duration.minDuration : null}
                                {language === "Dutch"
                                    ? item.duration.minDurationUnit
                                    : item.duration.minDurationUnit.replace("J", "A")}
                                {item.duration.maxDuration && (
                                    <>
                                        - {item.duration.maxDuration}
                                        {language === "Dutch"
                                            ? item.duration.maxDurationUnit
                                            : item.duration.maxDurationUnit.replace("J", "A")}
                                    </>
                                )}
                            </span>
                        </div>
                    )}
                    {item.remarks && item.remarks[language] && (
                        <div className={styles.group}>
                            <span className={styles.remarks}>{document?.data["admin_remarks"]}:</span>
                            <span>
                                <p>{item.remarks[language]}</p>
                            </span>
                        </div>
                    )}
                </>
            );
        }

        return (
            <span>
                {item.code} {item.name[language]}
            </span>
        );
    };

    return (
        <div className={styles.preview}>
            {isLoading ? (
                <Spinner />
            ) : booklet != null ? (
                <Tree items={booklet} render={(item) => renderTreeItem(item)} actions={() => " "} />
            ) : (
                <span>{document?.data["admin_select_version_to_visualize"]} </span>
            )}
        </div>
    );
};
