import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Radio, Select, Text , usePrismic } from "@buildwise/ui";

import { useEffect, useState } from "react";
import { config } from "../../_configuration/configuration";

import styles from "./TypeModal.module.css";

const TypeModal = ({ item, isEdit, booklet, parentId, language, isOpen, onSubmit, onClose }) => {
    const [canSave, setCanSave] = useState(false);

    const [dutchName, setDutchName] = useState("");
    const [frenchName, setFrenchName] = useState("");
    const [parentData, setParentData] = useState([]);
    const [parent, setParent] = useState([]);
    const [action, setAction] = useState(0);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (isEdit) {
                setDutchName(item.name.Dutch);
                setFrenchName(item.name.French);
                setAction(2);
            } else {
                setDutchName("");
                setFrenchName("");
                setAction(2);
            }

            if (!booklet) return;
            parseBookletData(booklet);
        }
    }, [isOpen, isEdit, item, booklet]);

    useEffect(() => {
        if (dutchName.trim() === "" || frenchName.trim() === "" || parent.length === 0) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [dutchName, frenchName, parent]);

    const parseBookletData = (booklet) => {
        const result = [];

        parseBookletChildren(booklet, result);
        const topLevel = {
            value: 0,
            isRoot: true,
            label: document?.data["booklets_last_type_top_level"],
        };
        result.unshift(topLevel);

        if (parentId) setParent([result.find((x) => x.id === parentId)]);
        else setParent([topLevel]);

        setParentData(result);
    };

    const parseBookletChildren = (child, result) => {
        for (let i = 0; i < child.length; i++) {
            const item = child[i];
            if (item.description) continue;
            result.push({
                ...item,
                isRoot: false,
                label: `${item.code}. ${item.name[language]}`,
            });

            if (item.children) parseBookletChildren(item.children, result, false);
        }
    };

    const onSave = () => {
        onSubmit &&
            onSubmit({
                dutchName,
                frenchName,
                parent: parent[0],
                isEdit: isEdit === true,
                action,
            });
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{isEdit ? document?.data["booklets_edit_type"] : document?.data["booklets_add_type"]}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <strong className={styles.label}>{document?.data["booklets_dutch_type_title"]}</strong>
                <Text
                    type="text"
                    onChange={(e) => setDutchName(e.target.value)}
                    value={dutchName}
                    placeholder={document?.data["booklets_dutch_type_title_placeholder"]}
                    required
                />

                <strong className={styles.label}>{document?.data["booklets_french_type_title"]}</strong>
                <Text
                    type="text"
                    onChange={(e) => setFrenchName(e.target.value)}
                    value={frenchName}
                    required
                    placeholder={document?.data["booklets_french_type_title_placeholder"]}
                />

                {isEdit !== true && (
                    <>
                        <Select
                            className={styles.dropdown}
                            label={document?.data["booklets_type_position"]}
                            options={parentData}
                            selection={parent}
                            onChangeSelection={(item) => setParent(item)}
                        />
                    </>
                )}

                <div style={{ paddingBottom: "16px" }}>
                    <label
                        style={{
                            fontWeight: "bold",
                            color: "#4F636D",
                            padding: "0 0 20px 16px",
                            display: "block",
                        }}
                    >
                        {document?.data["booklets_question_after_saving_type"]}
                    </label>
                    <Radio
                        label={document?.data["booklets_add_new_procedure_to_new_type"]}
                        name={"AfterAddAction"}
                        checked={action === 0}
                        onChange={() => setAction(0)}
                    />
                    <Radio
                        label={document?.data["booklets_add_new_type_to_new_type"]}
                        name={"AfterAddAction"}
                        checked={action === 1}
                        onChange={() => setAction(1)}
                    />
                    <Radio
                        label={document?.data["booklets_no_action_close_popup"]}
                        name={"AfterAddAction"}
                        checked={action === 2}
                        onChange={() => setAction(2)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="type-modal-close-button" variant="tertiary" onClick={() => onClose()}>
                    {document?.data["globals_cancel"]}
                </Button>
                <Button id="type-modal-save-button" onClick={() => onSave()} disabled={!canSave}>
                    {" "}
                    {isEdit ? document?.data["globals_edit"] : document?.data["globals_add"]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TypeModal;
