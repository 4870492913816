import { generatePath, useNavigate } from "react-router";
import { Plus } from "react-feather";
import { Button, Content, Panel, PanelBody, PanelFooter, useLanguage, usePrismic } from "@buildwise/ui";

import { config } from "../../_configuration/configuration";

import styles from "./Demo.module.css";

const Demo = () => {
    const { language } = useLanguage();
    const  navigate  = useNavigate();
    const [document] = usePrismic(config.prismic.documentType);
    const routeLang = language.split("-")[0];

    return (
        <Content className={styles.container}>
            <Panel className={styles.panel}>
                <PanelBody>{document?.data["booklets_booklet_desc"]}</PanelBody>
                <PanelFooter className={styles.dropdown}>
                    <Button id="demo-new-booklet-button" startIcon={<Plus />} onClick={() => navigate(generatePath(config.routes.newBooklet[language], {lang:routeLang}))} block>
                        {document?.data["booklets_new_booklet"]}
                    </Button>
                </PanelFooter>
            </Panel>
        </Content>
    );
};

export default Demo;
