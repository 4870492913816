import { Numeric, Select } from "@buildwise/ui";

import styles from "./Periodicity.module.css";

const Periodicity = ({ disabled, label, period, periods, value, onValueChanged, onPeriodChanged }) => {
    return (
        <div className={disabled === true ? `${styles.root} ${styles.disabled}` : styles.root}>
            <div>
                <Select
                    options={periods}
                    selection={period}
                    onChangeSelection={(item) => onPeriodChanged && onPeriodChanged(item)}
                />
            </div>
            {period[0] && period[0].value !== -1 && (
                <div className={styles.numeric}>
                    <Numeric value={value} onChange={(e) => onValueChanged && onValueChanged(e.target.value)} min={0} />
                </div>
            )}
            <div
                style={{
                    marginLeft: "32px",
                    lineHeight: "50px",
                    fontWeight: "bold",
                }}
            >
                {label}
            </div>
        </div>
    );
};

export default Periodicity;
