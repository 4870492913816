import { Checkbox } from "@buildwise/ui";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";

import styles from "./Tree.module.css";

const TreeItem = (props) => {
    const [indentation, setIndentation] = useState(30);

    useEffect(() => {
        if (props.indentation) setIndentation(props.indentation);
    }, []);

    const { item, level, actions, selectable, render, onToggleExpand, onCheckboxChanged } = props;

    const renderExpander = (item) => {
        return (
            <div
                className={styles.expander}
                // onClick={() => onToggleExpand(item)}
            >
                {item.children && item.children.length > 0 ? (
                    item.expanded ? (
                        <Icon.ChevronDown />
                    ) : (
                        <Icon.ChevronRight />
                    )
                ) : null}
            </div>
        );
    };

    const renderCheckbox = (item) =>
        selectable ? (
            <div className={styles.checkbox}>
                <Checkbox
                    checked={item.status === "checked"}
                    indeterminate={item.status === "indeterminate" ? item.status : undefined}
                    onChange={(e) => {
                        e.stopPropagation();
                        onCheckboxChanged(item, e.target.checked);
                    }}
                />
            </div>
        ) : null;

    const renderItem = (item) => render && <div className={styles.value}>{render(item)}</div>;

    const renderActions = (item) =>
        actions && (
            <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                {actions(item)}
            </div>
        );

    return item.visible !== false ? (
        <div
            className={styles.item}
            onClick={() => onToggleExpand(item)}
            style={{ paddingLeft: `${level * indentation}px` }}
        >
            {renderExpander(item)}
            {renderCheckbox(item)}
            {renderItem(item)}
            {renderActions(item)}
        </div>
    ) : null;
};

export default TreeItem;
