import { config } from "../_configuration/configuration";

export const getBooklets = async () => {
    const endpoint = `${config.api}api/v1/Booklets`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getBooklet = async (bookletId) => {
    const endpoint = `${config.api}api/v1/Booklets/${bookletId}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getTemplateBooklets = async () => {
    const endpoint = `${config.api}api/v1/StaticBooklets`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getTemplateBooklet = async () => {
    const endpoint = `${config.api}api/v1/StaticBooklets/Latest`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const getTemplateBookletById = async (id) => {
    const endpoint = `${config.api}api/v1/StaticBooklets/${id}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const createBooklet = async (booklet) => {
    const endpoint = `${config.api}api/v1/Booklets`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(booklet),
    };

    const response = await fetch(endpoint, options);
    if (response.ok) return await response.json();

    return undefined;
};

export const updateBooklet = async (booklet) => {
    const endpoint = `${config.api}api/v1/Booklets/${booklet.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(booklet),
    };

    const response = await fetch(endpoint, options);
    return response.ok;
};

export const partialUpdateBooklet = async (booklet) => {
    const endpoint = `${config.api}api/v1/Booklets/${booklet.id}`;
    const options = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(booklet),
    };

    const response = await fetch(endpoint, options);
    return response.ok;
};

export const deleteBooklet = async (bookletId) => {
    const endpoint = `${config.api}api/v1/Booklets/${bookletId}`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(endpoint, options);
    return response.ok;
};

export const downloadBooklet = async (filename, bookletId, language) => {
    const endpoint = `${config.api}api/v1/Booklets/${bookletId}/Download?language=${language}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${filename}.pdf`);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};

export const previewBooklet = async (bookletId, language) => {
    const endpoint = `${config.api}api/v1/Booklets/${bookletId}/Download?language=${language}`;
    const options = {
        method: "GET",
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    return window.URL.createObjectURL(blob);
};

export const downloadUnsavedBooklet = async (filename, booklet) => {
    const endpoint = `${config.api}api/v1/Export`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(booklet),
    };

    const response = await fetch(endpoint, options);
    if (!response.ok) return undefined;

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${filename}.pdf`);
    tempLink.click();
    window.URL.revokeObjectURL(url);
};
