import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { BuildwiseApplication, BuildwiseProvider } from "@buildwise/ui";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Routes from "./Routes";

import { AppContextProvider } from "./context/AppContextProvider";

import { config } from "./_configuration/configuration";

if (config.gtm) {
    TagManager.initialize(config.gtm);
}

if (config.sentry) {
    Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.sentry.env,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: config.sentry.sampleRate,
    });
}

const instance = new BuildwiseApplication({
    authentication: config.auth,
    feedbackEndpoint: config.feedback,
    prismic: config.prismic,
});

ReactDOM.render(
    <React.StrictMode>
        <BuildwiseProvider instance={instance}>
            <AppContextProvider>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </AppContextProvider>
        </BuildwiseProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
