import { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Text, usePrismic } from "@buildwise/ui";
import { ChevronDown } from "react-feather";
import Highlight from "../Highlight/Highlight";
import Tree from "../Tree/Tree";
import { config } from "../../_configuration/configuration";

import { AppContext } from "../../context/AppContextProvider";

import styles from "./TemplateModal.module.css";

const TemplateModal = ({ isOpen, onAdd, onClose, language, booklet }) => {
    const { state } = useContext(AppContext);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [template, setTemplate] = useState([]);
    const [library, setLibrary] = useState([]);
    const [expandLibrary, setExpandLibrary] = useState(false);
    const searchTimeout = useRef();

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen && template.length === 0 && state.template) {
            setTemplate(state.template.content);
        }
    }, [isOpen, state.template]);

    useEffect(() => {
        if (isOpen && template.length > 0) {
            const selected = [...template];
            handleInitialSelection(selected);
            setLibrary(selected);
        }
    }, [isOpen, template]);

    const handleInitialSelection = (array) => {
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            item.visible = true;

            if (item.children) {
                handleInitialSelection(item.children);

                if (item.children.every((x) => x.status === "checked")) {
                    item.status = "checked";
                } else if (item.children.some((x) => x.status === "checked" || x.status === "indeterminate")) {
                    item.status = "indeterminate";
                } else {
                    item.status = "unchecked";
                }
            } else {
                let found = false;
                if (!found) found = findBookletEntry(booklet, "originId", item.isProcedure === true, item.id);

                if (!found) found = findBookletEntry(booklet, "id", item.isProcedure === true, item.id);

                if (found) item.status = "checked";
                else item.status = "unchecked";
            }
        }
    };

    const findBookletEntry = (arr, prop, isProcedure, value) => {
        if (!arr) return false;

        for (const item of arr) {
            const result =
                item[prop] === value && (isProcedure ? item.isProcedure === true : true)
                    ? item
                    : findBookletEntry(item.children, prop, isProcedure, value);

            if (result) return result;
        }

        return false;
    };

    const renderTreeItem = (item) => {
        if (item.description) {
            const sources = [];

            if (item.sources) {
                for (let i = 0; i < item.sources.length; i++) {
                    sources.push(item.sources[i].shortForm);
                }
            }

            return (
                <>
                    {item.description && item.description[language] && (
                        <Highlight text={item.description[language]} term={filter} />
                    )}
                    {item.duration && (
                        <div className={styles.group}>
                            <span className={styles.periodicity}>{document?.data["booklets_periodicity"]}: </span>
                            <span>
                                {item.duration.minDuration > 0 ? item.duration.minDuration : null}
                                {language === "Dutch"
                                    ? item.duration.minDurationUnit
                                    : item.duration.minDurationUnit.replace("J", "A")}
                                {item.duration.maxDuration && (
                                    <>
                                        - {item.duration.maxDuration}
                                        {language === "Dutch"
                                            ? item.duration.maxDurationUnit
                                            : item.duration.maxDurationUnit.replace("J", "A")}
                                    </>
                                )}
                            </span>
                        </div>
                    )}
                    {item.remarks && item.remarks[language] && (
                        <div className={styles.group}>
                            <span className={styles.remarks}>{document?.data["booklets_remarks"]}:</span>
                            <span>
                                <Highlight text={item.remarks[language]} term={filter} />
                            </span>
                        </div>
                    )}
                    {/* {sources && sources.length > 0 && (
                        <div className={styles.group}>
                            <span className="sources">Bronnen:</span>
                            <span>{sources.join(", ")}</span>
                        </div>
                    )} */}
                </>
            );
        }

        return <Highlight text={`${item.code} ${item.name[language]}`} term={filter} useSpan />;
    };

    const expandEntireLibrary = () => {
        for (let i = 0; i < library.length; i++) {
            expandItem(library[i]);
        }

        setExpandLibrary((b) => !b);
    };

    const expandItem = (item) => {
        item.expanded = !expandLibrary;

        if (item.children && item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
                expandItem(item.children[i]);
            }
        }
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="large">
            <ModalHeader closeButton>
                <ModalTitle>{document?.data["booklets_add_library_procedures"]}</ModalTitle>
            </ModalHeader>
            <ModalBody useAccent>
                <div className={styles.actions}>
                    <Button
                        id="template-modal-expand-entire-library-button"
                        className={styles.middle}
                        variant="secondary"
                        onClick={() => expandEntireLibrary()}
                        startIcon={<ChevronDown />}
                    >
                        {expandLibrary ? document?.data["booklets_collapse_all_procedures"] : document?.data["booklets_expand_all_procedures"]}
                    </Button>
                    <Text
                        type="text"
                        style={{ margin: 0, minWidth: "300px" }}
                        value={search}
                        placeholder={document?.data["booklets_search"]}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            if (searchTimeout.current) clearTimeout(searchTimeout.current);

                            searchTimeout.current = setTimeout(() => {
                                setFilter(e.target.value);
                            }, 500);
                        }}
                    />
                </div>
                <div>
                    <Tree
                        items={library}
                        selectable
                        render={(item) => renderTreeItem(item)}
                        filter={filter}
                        search={(item, val) => {
                            if (
                                item.name &&
                                item.name[language] &&
                                item.name[language].toLowerCase().indexOf(val.toLowerCase()) > -1
                            )
                                return true;

                            if (
                                item.isProcedure &&
                                item.remarks &&
                                item.remarks[language] &&
                                item.remarks[language].toLowerCase().indexOf(val.toLowerCase()) > -1
                            )
                                return true;

                            return false;
                        }}
                        actions={(_) => " "}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button id="template-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document?.data["globals_cancel"]}
                </Button>
                <Button id="template-modal-add-button" onClick={() => onAdd(library)}>{document?.data["booklets_add_to_booklet"]}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default TemplateModal;
