import { useContext, useEffect, useState } from "react";
import { generatePath, useParams, useNavigate } from "react-router";
import {
    Button,
    Content,
    ContentBody,
    ContentHeader,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    useLanguage,
    usePrismic,
} from "@buildwise/ui";
import BookletModal from "../../components/BookletModal/BookletModal";
import BookletTable from "../../components/BookletTable/BookletTable";

import { AppContext } from "../../context/AppContextProvider";
import { deleteBooklet, getBooklets } from "../../adapters/BookletsAdapter";
import { config } from "../../_configuration/configuration";

const Booklets = () => {
    const { state, dispatch } = useContext(AppContext);
    const [booklet, setBooklet] = useState(null);
    const [warning, setWarning] = useState({ visible: false });
    const navigate = useNavigate();
    const params = useParams();

    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (state.booklets?.length > 0) return;
        getBooklets().then(storeBooklets);
    }, []);

    const storeBooklets = (booklets) => {
        if (booklets) dispatch({ type: "SET_BOOKLETS", payload: booklets });
    };

    const onView = (booklet) => navigate(generatePath(config.routes.booklet[language], { ...params, id: booklet.id }));

    const onEdit = (booklet) => setBooklet(booklet);

    const onDelete = (booklet, confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: document.data.modals_remove_booklet_title,
                text: document?.data["booklets_remove_name_desc"].replace("{{name}}", booklet.name),
                callback: (bool) => onDelete(booklet, true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteBooklet(booklet.id).then((success) => removeBooklet(success, booklet.id));
    };

    const removeBooklet = (success, id) => {
        if (!success) return;

        dispatch({ type: "REMOVE_BOOKLET", payload: id });
    };

    return (
        <>
            <Content>
                <ContentHeader>
                    <h1>{document?.data["booklets_my_booklets"]}</h1>
                </ContentHeader>
                <ContentBody>
                    <BookletTable data={state.booklets} onView={onView} onEdit={onEdit} onDelete={onDelete} />
                </ContentBody>

                <BookletModal isOpen={Boolean(booklet)} booklet={booklet} onClose={() => setBooklet(null)} />
            </Content>

            {warning.visible && (
                <Modal open={warning.visible} backdrop onClose={warning.callback}>
                    <ModalHeader closeButton>
                        <ModalTitle>{warning.title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>{warning.text}</ModalBody>
                    <ModalFooter>
                        <Button id="booklets-warning-cancel-button" variant="tertiary" onClick={() => setWarning({ visible: false })}>
                            {document?.data["globals_cancel"]}
                        </Button>
                        <Button id="booklets-warning-confirm-button" onClick={warning.callback}>{document?.data["globals_yes"]}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default Booklets;
