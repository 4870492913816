import { Route, Routes, useNavigate } from "react-router-dom";
import { Spinner, useAuthentication, usePrismic } from "@buildwise/ui";

import App from "./App";
import Demo from "./pages/demo/Demo";
import Booklets from "./pages/booklets/Booklets";
import Booklet from "./pages/booklet/Booklet";
import Manage from "./pages/admin/Manage";

import { config } from "./_configuration/configuration";
import { useEffect } from "react";

const AppRoutes = () => {
    const { isAuthenticated } = useAuthentication();
    const [, { state: prismicState }] = usePrismic(config.prismic.documentType);

    return prismicState === "loaded" ? (
        <Routes>
            <Route index element={<App />} />
            <Route path=":lang" element={<App />}>
                <Route index element={isAuthenticated ? <Booklets /> : <Demo />} />
                <Route path={"gids"}>
                    <Route path={":id"}>
                        <Route index element={<Booklet />} />
                        <Route path={"aanpassen"} element={<Booklet />} />
                    </Route>
                </Route>

                <Route path={"guide"}>
                    <Route path={":id"}>
                        <Route index element={<Booklet />} />
                        <Route path={"modifier"} element={<Booklet />} />
                    </Route>
                </Route>

                <Route path={"admin"} element={<Manage />} />

                <Route path={"login"} element={<Login />} />
            </Route>
        </Routes>
    ) : (
        <Spinner />
    );
};

export default AppRoutes;

const Login = () => {
    const { loginRedirect, isAuthenticated } = useAuthentication();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) navigate("/");
        else loginRedirect();
    }, []);

    useEffect(() => {
        if (isAuthenticated) navigate("/");
    }, [isAuthenticated]);

    return <Spinner />;
};
