import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    QuestionBox,
    QuestionBoxBody,
    Text,
    usePrismic
} from "@buildwise/ui";
import { config } from "../../_configuration/configuration";

import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContextProvider";
import { getBooklets, createBooklet, partialUpdateBooklet } from "../../adapters/BookletsAdapter";

import styles from "./BookletModal.module.css";

const BookletModal = ({ isOpen, onClose, onSubmit, booklet }) => {
    const { state, dispatch } = useContext(AppContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => {
        if (isOpen) {
            if (state.booklets?.length > 0) return;
            getBooklets().then(storeBooklets);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setCanSave(true);
            setIsSaving(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (booklet) {
            setName(booklet.name);
            setDescription(booklet.description);
        }
    }, [booklet]);

    useEffect(() => {
        if (isSaving) {
            setCanSave(false);
            return;
        }

        if (!name || name.trim().length === 0 || !validateBookletName(name)) {
            setCanSave(false);
            return;
        }

        setCanSave(true);
    }, [name, isSaving]);

    const validateBookletName = (name) => {
        return !state.booklets.find(
            (x) => x.name?.toLowerCase() === name?.trim()?.toLowerCase() && x.id !== booklet.id
        );
    };

    const onSave = () => {
        setIsSaving(true);
        if (Boolean(booklet.id)) {
            partialUpdateBooklet({
                id: booklet.id,
                name: name,
                description: description,
                language: booklet.language,
            }).then(updateBooklets);
        } else {
            const data = cleanupJson(booklet.content);
            createBooklet({ ...booklet, content: data, name: name, description: description }).then(addBooklet);
        }
    };

    const cleanupJson = (arr) => {
        const result = [];

        if (arr) {
            for (let i = 0, len = arr.length; i < len; i++) {
                const element = arr[i];
                const clone = { ...element };
                clone.children = cleanupJson(element.children);
                delete clone.parent;

                if (!clone.isProcedure) {
                    delete clone.id;
                }

                result.push(clone);
            }
        }

        return result;
    };

    const storeBooklets = (booklets) => {
        if (booklets) dispatch({ type: "SET_BOOKLETS", payload: booklets });
    };

    const addBooklet = (booklet) => {
        if (!booklet) return;

        dispatch({ type: "ADD_BOOKLET", payload: booklet });
        onSubmit && onSubmit(booklet);
    };

    const updateBooklets = (success) => {
        if (!success) return;

        dispatch({ type: "UPDATE_BOOKLET", payload: { id: booklet.id, name: name, description: description } });
        onClose && onClose();
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{Boolean(booklet?.id) ? document?.data["booklets_edit_details"] : document?.data["booklets_new_booklet"]}</ModalTitle>
            </ModalHeader>
            <ModalBody className={styles.body}>
                <QuestionBox className={styles.input}>
                    <QuestionBoxBody>
                        <strong className={styles.label}>{document?.data["globals_name"]}</strong>
                        <Text
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={document?.data["booklets_name_placeholder"]}
                            required
                            onValidate={validateBookletName}
                            validationError={document?.data["booklets_name_already_exists"]}
                        />
                        <strong className={styles.label} style={{ marginTop: "16px" }}>
                            {document?.data["booklets_short_desc"]}
                        </strong>
                        <Text
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={document?.data["booklets_short_desc_placeholder"]}
                        />
                    </QuestionBoxBody>
                </QuestionBox>
            </ModalBody>
            <ModalFooter>
                <Button id="booklet-modal-cancel-button" variant="tertiary" onClick={onClose}>
                    {document?.data["globals_cancel"]}
                </Button>
                <Button id="booklet-modal-save-button" onClick={onSave} disabled={!canSave}>
                    {isSaving === true ? document?.data["booklets_busy_saving"] : document?.data["globals_save"]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default BookletModal;
