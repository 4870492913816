import {
    Box,
    BoxBody,
    BoxTitle,
    Breadcrumb,
    Button,
    ButtonGroup,
    Content,
    ContentActions,
    ContentBody,
    ContentFooter,
    ContentHeader,
    ContentInfo,
    DropdownButton,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Panel,
    PanelBody,
    PanelHeader,
    Select,
    Separator,
    Spinner,
    Text,
    useAuthentication,
    useLanguage,
    useToasts,
    usePrismic
} from "@buildwise/ui";
import { useContext, useEffect, useRef, useState } from "react";
import { ChevronDown, Download, Edit, Eye, Plus, Trash2 } from "react-feather";
import { useParams, useMatch } from "react-router";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
    deleteBooklet,
    downloadBooklet,
    getBooklet,
    getTemplateBooklet,
    updateBooklet,
} from "../../adapters/BookletsAdapter";
import BookletModal from "../../components/BookletModal/BookletModal";
import Highlight from "../../components/Highlight/Highlight";
import PdfPreview from "../../components/PdfPreview/PdfPreview";
import ProcedureModal from "../../components/ProcedureModal/ProcedureModal";
import TemplateModal from "../../components/TemplateModal/TemplateModal";
import Tree from "../../components/Tree/Tree";
import TypeModal from "../../components/TypeModal/TypeModal";
import { AppContext } from "../../context/AppContextProvider";
import { config } from "../../_configuration/configuration";
import { useNavigate, generatePath } from "react-router";
import styles from "./Booklet.module.css";
import {PrismicRichText} from "@prismicio/react"
const Booklet = () => {
    const { state, dispatch } = useContext(AppContext);
    const { isAuthenticated } = useAuthentication();
    const { sendToast } = useToasts();
    const { language } = useLanguage();
    const [document] = usePrismic(config.prismic.documentType);

    const workingLanguages = [
        { value: "Dutch", label: document?.data["globals_dutch"] },
        { value: "French", label: document?.data["globals_french"] },
    ];

    const [isAddingFromLibrary, setIsAddingFromLibrary] = useState(false);

    const [workingLanguage, setWorkingLanguage] = useState([workingLanguages.find((x) => x.value === (language==="nl-be"?"Dutch":"French") )]);
    const [booklet, setBooklet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [addOrEditType, setAddOrEditType] = useState({ open: false });
    const [addOrEditProcedure, setAddOrEditProcedure] = useState({ open: false });

    const [canSave, setCanSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDownloading, setIsDownloading] = useState({});
    const [warning, setWarning] = useState({ visible: false });
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");

    const [expandLibrary, setExpandLibrary] = useState(false);
    const searchTimeout = useRef();
    const navigate = useNavigate();

    const params = useParams();
    const editBookletRoute = useMatch({
        path: config.routes.editBooklet[language],
        strict: true,
        sensitive: false,
    });

    const { id } = params;
    const isNew = id === "nieuw" || id === "nouveau";
    const isEdit = editBookletRoute !== null;

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    useEffect(() => {
        if (isNew) {
            setBooklet({ name: "", description: "", content: [], language: language === "nl-be" ? "Dutch" : "French" });
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (!state.template) getTemplateBooklet().then(storeTemplate);
    }, []);

    useEffect(() => {
        if (!isNew && isAuthenticated) {
            getBooklet(id).then(parseBooklet);
        }
    }, [id, isAuthenticated]);

    useEffect(() => {
        if (booklet.content && workingLanguage && workingLanguage[0]) {
            setBooklet({ ...booklet, language: workingLanguage[0].value });
        }
    }, [workingLanguage]);

    useEffect(() => {
        const data = state.booklets.find((x) => x.id === booklet.id);
        if (data) setBooklet({ ...booklet, name: data.name, description: data.description });
    }, [state.booklets]);

    useEffect(() => {
        setCanSave(booklet.content && booklet.content.length > 0);
    }, [booklet.content]);

    const parseBooklet = (booklet) => {
        transform(booklet.content);
        setBooklet(booklet);
    };

    const transform = (booklet) => {
        for (let i = 0, len = booklet.length; i < len; i++) {
            const entry = booklet[i];

            if (entry.code) {
                // Type
                entry.id = entry.code;
                entry.originId = entry.orgCode;
                entry.isProcedure = false;
            } else {
                // Procedure
                entry.originId = entry.orgId;
                entry.isProcedure = true;
            }

            if (entry.children) transform(entry.children);
        }
    };

    const storeTemplate = (template) => {
        if (template) dispatch({ type: "SET_TEMPLATE", payload: template });
    };

    const getSelectedLibraryItems = (template) => {
        const items = [];
        for (let i = 0; i < template.length; i++) {
            handleSelectedLibraryItems(template[i], null, items);
        }

        return items;
    };

    const handleSelectedLibraryItems = (item, parent, items) => {
        if (item.status !== "checked" && item.status !== "indeterminate") return;

        const newItem = { ...item, isNew: true, expanded: false, children: [] };

        if (parent) parent.children.push(newItem);
        else items.push(newItem);

        if (!item.children) return;

        for (let i = 0; i < item.children.length; i++) {
            const child = item.children[i];
            handleSelectedLibraryItems(child, newItem, items);
        }
    };

    const mergeLibrarySelectionIntoBooklet = (booklet, selection) => {
        const result = [];

        for (let i = 0; i < booklet.length; i++) {
            const selectedNode = selection.find((x) =>
                booklet[i].isNew ? x.id === booklet[i].id : x.id === booklet[i].originId
            );
            if (!selectedNode) result.push(booklet[i]);
        }

        for (let i = 0; i < selection.length; i++) {
            const bookletNode = booklet.find((x) =>
                x.isNew ? x.id === selection[i].id : x.originId === selection[i].id
            );

            if (!bookletNode) {
                result.push(selection[i]);
            } else {
                const parent = { ...bookletNode, children: [] };
                result.push(parent);
                mergeChildren(parent, bookletNode, selection[i]);
            }
        }

        return result.sort((a, b) => (a.order < b.order ? -1 : 1));
    };

    const mergeChildren = (parent, bookletNode, selectionNode) => {
        if (bookletNode.children && bookletNode.children.length > 0) {
            for (let i = 0; i < bookletNode.children.length; i++) {
                const selectedNode = selectionNode.children.find((x) =>
                    bookletNode.children[i].isNew
                        ? bookletNode.children[i].id === x.id
                        : bookletNode.children[i].originId === x.id
                );
                if (!selectedNode) parent.children.push(bookletNode.children[i]);
            }
        }

        if (!selectionNode.children && selectionNode.children.length === 0) return;

        for (let i = 0; i < selectionNode.children.length; i++) {
            const childNode = bookletNode.children.find(
                (x) => x.originId === selectionNode.children[i].id || (x.isNew && x.id === selectionNode.children[i].id)
            );

            if (!childNode) {
                parent.children.push(selectionNode.children[i]);
            } else {
                const childParent = { ...childNode, children: [] };
                parent.children.push(childParent);
                mergeChildren(childParent, childNode, selectionNode.children[i]);
            }
        }

        parent.children = parent.children.sort((a, b) => (a.order < b.order ? -1 : 1));
    };

    const findBookletEntry = (arr, prop, isProcedure, value) => {
        if (!arr) return false;

        for (const item of arr) {
            const result =
                item[prop] === value &&
                    (isProcedure ? item.isProcedure === true : item.isProcedure === false || item.isProcedure === undefined)
                    ? item
                    : findBookletEntry(item.children, prop, isProcedure, value);

            if (result) return result;
        }

        return false;
    };

    const onAddSelectionFromLibrary = (template) => {
        const selection = getSelectedLibraryItems(template);
        const currentBooklet = { ...booklet };
        const merged = mergeLibrarySelectionIntoBooklet(currentBooklet.content, selection);

        currentBooklet.content = merged;

        setBooklet(currentBooklet);
        setIsAddingFromLibrary(false);
    };

    const removeBookletItem = (item) => {
        const data = [...booklet.content];
        removeNode(data, item);
        setBooklet({ ...booklet, content: data });
    };

    const removeNode = (booklet, item) => {
        booklet.forEach((parent) => deleteFromTree(parent, booklet, item));
    };

    const deleteFromTree = (obj, parent, item) => {
        if (obj === item) {
            var index = parent.findIndex((o) => o.id === obj.id);
            parent.splice(index, 1);

            return;
        }

        if (obj.children && obj.children.length > 0) {
            obj.children.forEach((child) => {
                deleteFromTree(child, obj.children, item);
            });
        }
    };

    const handleAddOrEditType = (result) => {
        const data = [...booklet.content];
        let parentId;

        if (result.isEdit) {
            parentId = addOrEditType.item.id;
            addOrEditType.item.name.Dutch = result.dutchName;
            addOrEditType.item.name.French = result.frenchName;
            if (addOrEditType.item.originId) addOrEditType.item.differsFromOriginal = true;
        } else {
            setCode(result);
            result.id = result.code;
            parentId = result.id;
            insertCustomItem(data, result);
        }

        setBooklet({ ...booklet, content: data });
        if (result.isEdit) {
            sendToast(
                "success",
                document?.data["booklets_type_edited_notification_title"].replace("{{code}}", result.code),
                document?.data["booklets_type_edited_notification_desc"].replace("{{code}}", result.code).replace("{{title}}", workingLanguage === "Dutch" ? result.dutchName : result.frenchName),
                5000
            );
        } else {
            sendToast(
                "success",
                document?.data["booklets_type_added_notification_title"].replace("{{code}}", result.code),
                document?.data["booklets_type_added_notification_desc"]
                    .replace("{{code}}", result.code)
                    .replace("{{title}}", workingLanguage === "Dutch" ? result.dutchName : result.frenchName)
                ,
                5000
            );
        }

        switch (result.action) {
            case 0:
                setAddOrEditType({ open: false });
                setAddOrEditProcedure({ open: true, parentId: parentId });
                break;
            case 1:
                setAddOrEditType({ open: true, parentId: parentId, isEdit: false });
                break;
            case 2:
            default:
                setAddOrEditType({ open: false });
                break;
        }
    };

    const setCode = (type) => {
        const data = [...booklet.content];

        if (!type.parent.isRoot) {
            const parentCode = type.parent.code;
            const bookletParent = findBookletEntry(data, "code", false, parentCode);
            let templateParent;
            if (type.parent.originId)
                templateParent = findBookletEntry(state.template.content, "code", false, parentCode);

            if (templateParent) {
                const typeChildren = templateParent.children.filter((x) => !x.isProcedure);
                if (typeChildren.length > 0) {
                    const lastChild = typeChildren[typeChildren.length - 1];
                    const lastChildCode = lastChild.code.split(".").map((x) => Number(x));

                    type.code = `${parentCode}.${lastChildCode[lastChildCode.length - 1] + 1}`;
                    type.order = Number(lastChild.order) + 1;
                } else {
                    type.code = `${parentCode}.1`;
                    type.order = 1;
                }
            }

            if (bookletParent && bookletParent.children) {
                const typeChildren = bookletParent.children.filter((x) => !x.isProcedure);
                if (typeChildren.length > 0) {
                    const lastChild = typeChildren[typeChildren.length - 1];
                    const lastChildCode = lastChild.code.split(".").map((x) => Number(x));

                    const code = `${parentCode}.${lastChildCode[lastChildCode.length - 1] + 1}`;
                    const order = Number(lastChild.order) + 1;
                    if (!type.order || order > type.order) {
                        type.code = code;
                        type.order = order;
                    }
                }
            }

            if (!type.code) {
                type.code = `${parentCode}.1`;
                type.order = 0;
            }
        } else {
            const lastItem = data[data.length - 1];
            const code = Number(lastItem.code);
            type.code = (code < 10 ? 10 : code + 1).toString();
            type.order = Number(type.code - 1);
        }
    };

    const generateUUID = () => {
        let d = new Date().getTime();
        let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }

            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    };

    const handleAddOrEditProcedure = (result) => {
        const data = [...booklet.content];
        let parentId = result.parent.id;

        if (result.isEdit) {
            addOrEditProcedure.item.description = {
                Dutch: result.dutchProcedure,
                French: result.frenchProcedure,
            };
            addOrEditProcedure.item.remarks = {
                Dutch: result.dutchRemarks,
                French: result.frenchRemarks,
            };
            addOrEditProcedure.item.duration = result.duration;
            addOrEditProcedure.item.isProcedure = true;

            if (addOrEditProcedure.item.originId) addOrEditProcedure.item.differsFromOriginal = true;
        } else {
            result.id = generateUUID();
            insertCustomItem(data, result, true);
        }

        setBooklet({ ...booklet, content: data });

        if (result.isEdit) {
            sendToast(
                "success",
                document?.data["booklets_procedure_edited_notification_title"],
                document?.data["booklets_procedure_edited_notification_desc"].replace(
                    "{{name}}", workingLanguage === "Dutch" ? result.dutchProcedure : result.frenchProcedure,
                ),
                5000
            );
        } else {
            sendToast(
                "success",
                document?.data["booklets_procdure_added_notification_title"],
                document?.data["booklets_procedure_added_notification_desc"].replace(
                    "{{name}}", workingLanguage === "Dutch" ? result.dutchProcedure : result.frenchProcedure,
                ),
                5000
            );
        }

        switch (result.action) {
            case 0:
                setAddOrEditProcedure({
                    visible: true,
                    parentId: parentId,
                    isEdit: false,
                    item: {},
                });
                break;
            case 1:
            default:
                setAddOrEditProcedure({ visible: false });
                break;
        }
    };

    const insertCustomItem = (booklet, newItem, isProcedure = false) => {
        let insert;
        if (isProcedure) {
            insert = {
                id: newItem.id,
                isProcedure: true,
                description: {
                    Dutch: newItem.dutchProcedure,
                    French: newItem.frenchProcedure,
                },
                remarks: {
                    Dutch: newItem.dutchRemarks,
                    French: newItem.frenchRemarks,
                },
                duration: newItem.duration,
                isNew: true,
                isCustom: true,
                parent: newItem.parent,
                order: newItem.order,
            };
        } else {
            insert = {
                id: newItem.id,
                originId: null,
                name: {
                    Dutch: newItem.dutchName,
                    French: newItem.frenchName,
                },
                code: newItem.code,
                order: newItem.order,
                isNew: true,
                isCustom: true,
                parent: newItem.parent,
            };
        }

        if (newItem.parent.isRoot === true) {
            booklet.push(insert);
            return;
        }

        const parent = findBookletEntry(booklet, "code", false, newItem.parent.code);
        if (!parent.children) parent.children = [];
        if (isProcedure)
            insert.order = parent.children.length > 0 ? parent.children[parent.children.length - 1].order + 1 : 0;
        parent.children.push(insert);
    };

    const expandEntireLibrary = () => {
        for (let i = 0; i < booklet.content.length; i++) {
            expandItem(booklet.content[i]);
        }

        setExpandLibrary((b) => !b);
    };

    const expandItem = (item) => {
        item.expanded = !expandLibrary;

        if (item.children && item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
                expandItem(item.children[i]);
            }
        }
    };

    const renderMenu = () => {
        return isNew ? (
            <>
                <Button
                    id="new-booklet-cancel-button"
                    variant="tertiary"
                    className="booklet-cancel"
                    onClick={() => {
                        navigate(generatePath(config.routes.landing[language], { ...params }));
                    }}
                    style={{ margin: "5px" }}
                >
                    {document?.data["globals_cancel"]}
                </Button>
                <Button
                    id="new-booklet-save-button"
                    variant="secondary"
                    className={isAuthenticated ? "booklet-save" : "booklet-save transparent"}
                    disabled={(isAuthenticated && !canSave) || isSaving}
                    onClick={() => isAuthenticated && setIsSaving(true)}
                    style={{ margin: "5px" }}
                    data-for="tooltip"
                    data-tip={document?.data["booklets_user_feature"]}
                    data-tip-disable={isAuthenticated}
                >
                    {document?.data["booklets_save_as"]}
                </Button>
            </>
        ) : isEdit ? (
            <>
                <Button
                    id="edit-booklet-cancel-button"
                    variant="tertiary"
                    className="booklet-cancel"
                    onClick={() => navigate(generatePath(config.routes.landing[language]), { ...params })}
                    style={{ margin: "5px" }}
                >
                    {document?.data["globals_cancel"]}
                </Button>
                <Button
                    id="edit-booklet-edit-details-button"
                    variant="secondary"
                    className="booklet-edit"
                    onClick={() => setIsSaving(true)}
                    style={{ margin: "5px" }}
                    startIcon={<Edit />}
                    data-tip-disable={isAuthenticated}
                >
                    {document?.data["booklets_edit_details"]}
                </Button>
                <Button
                    id="edit-booklet-save-and-view-in-pdf-button"
                    variant="primary"
                    className="booklet-pdf"
                    onClick={() => onSave(true)}
                    style={{ margin: "5px" }}
                >
                    {document?.data["booklets_save_and_view_in_pdf"]}
                </Button>
                <Button
                    id="dit-booklet-save-button"
                    variant="primary"
                    className="booklet-save"
                    onClick={() => onSave()}
                    style={{ margin: "5px" }}
                    disabled={isUpdating}
                >
                    {isUpdating ? document?.data["booklets_busy_saving"] : document?.data["globals_save"]}
                </Button>
            </>
        ) : (
            <>
                <Button
                    id="booklet-delete-button"
                    variant="tertiary"
                    className="booklet-delete"
                    onClick={() => onDelete()}
                    style={{ margin: "5px" }}
                    startIcon={<Trash2 />}
                >
                    {document?.data["globals_delete"]}
                </Button>
                <Button
                    id="booklet-edit-button"
                    variant="secondary"
                    className="booklet-edit"
                    onClick={() => navigate(generatePath(config.routes.editBooklet[language], { ...params, id }))}
                    style={{ margin: "5px" }}
                    startIcon={<Edit />}
                >
                    {document?.data["booklets_edit_booklet"]}
                </Button>
                <Button
                    id="booklet-nl-download-button"
                    variant="secondary"
                    className="booklet-download-nl"
                    onClick={() => onDownload("Dutch")}
                    disabled={isDownloading.Dutch}
                    style={{ margin: "5px" }}
                    startIcon={<Download />}
                >
                    {document?.data["booklets_dutch_pdf"]}
                </Button>
                <Button
                    id="booklet-fr-download-button"
                    variant="secondary"
                    className="booklet-download-fr"
                    onClick={() => onDownload("French")}
                    disabled={isDownloading.French}
                    style={{ margin: "5px" }}
                    startIcon={<Download />}
                >
                    {document?.data["booklets_french_pdf"]}
                </Button>
            </>
        );
    };

    const onDownload = (lng) => {
        let filename = `${document?.data["booklets_booklet"]}-${lng === "Dutch" ? "nl" : "fr"}.pdf`;
        if (booklet.name && booklet.name.length > 0) filename = `${booklet.name}-${lng === "Dutch" ? "nl" : "fr"}`;

        setIsDownloading({ ...isDownloading, [lng]: true });
        downloadBooklet(filename, id, lng).then((x) => setIsDownloading({ ...isDownloading, [lng]: false }));
    };

    const onSave = (preview) => {
        setIsUpdating(true);
        const data = cleanupJson(booklet.content);
        updateBooklet({ ...booklet, content: data }).then(() => {
            setIsUpdating(false);
            if (preview) navigate(generatePath(config.routes.booklet[language], { ...params, id }));
            else getBooklet(id).then(parseBooklet);
        });
    };

    const onDelete = (confirmed = false, result = false) => {
        if (!confirmed) {
            setWarning({
                visible: true,
                prompt: true,
                title: document?.data["booklets_remove_title"],
                text: document?.data["booklets_remove_name_desc"].replace("{{name}}", booklet.name),
                callback: (bool) => onDelete(true, bool),
            });

            return;
        }

        setWarning({ visible: false });

        if (!result) return;

        deleteBooklet(id).then((success) => removeBooklet(success, id));
    };

    const removeBooklet = (success, id) => {
        if (!success) return;

        dispatch({ type: "REMOVE_BOOKLET", payload: id });
        navigate(generatePath(config.routes.landing[language], { ...params }));
    };

    const cleanupJson = (arr) => {
        const result = [];

        for (let i = 0, len = arr.length; i < len; i++) {
            const element = arr[i];
            const clone = { ...element };
            if (element.children) clone.children = cleanupJson(element.children);
            delete clone.parent;

            if (!clone.isProcedure) {
                delete clone.id;
            }

            result.push(clone);
        }

        return result;
    };

    const onSaved = (result) => {
        navigate(generatePath(config.routes.editBooklet[language], { ...params, id }));
        setIsSaving(false);
    };

    const renderBookletActions = () => {
        return (
            <>
                <Select
                    className={styles.language}
                    label={document?.data["booklets_select_working_language"]}
                    description={document?.data["booklets_possibility_working_language"]}
                    options={workingLanguages}
                    selection={workingLanguage}
                    onChangeSelection={(selection) => setWorkingLanguage(selection)}
                />
                <div className={styles.menu}>
                    <Button id="booklet-add-procedure-from-library-button" variant="secondary" onClick={() => setIsAddingFromLibrary(true)} startIcon={<Plus />}>
                        {document?.data["booklets_library_procedures"]}
                    </Button>
                    <DropdownButton label={document?.data["booklets_add_own_procedure_button"]} startIcon={<Plus />} variant="secondary">
                        <DropdownItem>
                            <p
                                id="booklet-add-custom-procedure-to-existing-type-button"
                                className={styles.pointer}
                                onClick={() =>
                                    setAddOrEditProcedure({
                                        open: true,
                                        procedure: {},
                                        parent: null,
                                    })
                                }
                            >
                                {document?.data["booklets_child_of_existing_type"]}
                            </p>
                        </DropdownItem>
                        <DropdownItem>
                            <p
                                id="booklet-add-custom-procedure-to-new-type-button"
                                className={styles.pointer}
                                onClick={() =>
                                    setAddOrEditType({
                                        open: true,
                                        type: {},
                                        parent: null,
                                    })
                                }
                            >
                                {document?.data["booklets_child_of_new_type"]}
                            </p>
                        </DropdownItem>
                    </DropdownButton>
                </div>
            </>
        );
    };

    const renderTreeItem = (item) => {
        if (item.description) {
            const sources = [];

            if (item.sources) {
                for (let i = 0; i < item.sources.length; i++) {
                    sources.push(item.sources[i].shortForm);
                }
            }

            return (
                <>
                    {item.description && item.description[workingLanguage[0].value] && (
                        <>
                            <Highlight text={item.description[workingLanguage[0].value]} term={filter} />
                            {renderLabel(item)}
                        </>
                    )}

                    {item.duration && (
                        <div className={styles.group}>
                            <span className={styles.periodicity}>{document?.data["booklets_periodicity"]}: </span>
                            <span>
                                {item.duration.minDuration > 0 ? item.duration.minDuration : null}
                                {workingLanguage[0].value === "Dutch"
                                    ? item.duration.minDurationUnit
                                    : item.duration.minDurationUnit.replace("J", "A")}
                                {item.duration.maxDuration && (
                                    <>
                                        - {item.duration.maxDuration}
                                        {workingLanguage[0].value === "Dutch"
                                            ? item.duration.maxDurationUnit
                                            : item.duration.maxDurationUnit.replace("J", "A")}
                                    </>
                                )}
                            </span>
                        </div>
                    )}

                    {item.remarks && item.remarks[workingLanguage[0].value] && (
                        <div className={styles.group}>
                            <span className={styles.remarks}>{document?.data["booklets_remarks"]}:</span>
                            <span>
                                <Highlight text={item.remarks[workingLanguage[0].value]} term={filter} />
                            </span>
                        </div>
                    )}
                    {/* {sources && sources.length > 0 && (
                        <div className={styles.group}>
                            <span className="sources">Bronnen:</span>
                            <span>{sources.join(", ")}</span>
                        </div>
                    )} */}
                </>
            );
        }

        return (
            <>
                <span>
                    <Highlight text={`${item.code} ${item.name[workingLanguage[0].value]}`} term={filter} useSpan />
                </span>{" "}
                {renderLabel(item)}
            </>
        );
    };

    const renderLabel = (item) => {
        let label;
        if (item.isNew && item.isCustom) {
            label = "added";
        } else if (!item.originId && item.differsFromOriginal) {
            label = "added";
        } else if (item.differsFromOriginal) {
            label = "modified";
        } else if (!item.isNew && !item.originId) {
            label = "added";
        }

        if (!label) return null;

        const svg = label === "added" ? <Plus /> : <Edit />;

        return (
            <div className={`${styles.label} ${styles[label]} ${item.description ? styles.procedure : ""}`}>
                {svg}
                <span>{label === "added" ? document?.data["booklets_added"] : document?.data["booklets_modified"]}</span>
            </div>
        );
    };

    const renderActions = (item) => {
        if (item.description) {
            return (
                <div className={styles.actions}>
                    <button
                        id="booklet-procedure-action-edit-button"
                        onClick={() =>
                            setAddOrEditProcedure({
                                open: true,
                                item: item,
                                isEdit: true,
                            })
                        }
                    >
                        <Edit />
                    </button>
                    <button id="booklet-procedure-action-remove-button" onClick={() => removeBookletItem(item)}>
                        <Trash2 />
                    </button>
                </div>
            );
        }

        return (
            <div className={styles.actions}>
                <div className={styles.dropdown}>
                    <div className={styles.dropdownbutton}>
                        <p>
                            <Plus />
                            <span>{document?.data["globals_add"]}</span>
                        </p>
                    </div>
                    <div className={styles.dropdownitems}>
                        <ul>
                            <li
                                id="booklet-type-action-add-custom-type-button"
                                onClick={() =>
                                    setAddOrEditType({
                                        open: true,
                                        type: {},
                                        parentId: item.id,
                                    })
                                }
                            >
                                <p>
                                    <Plus />
                                    {document?.data["booklets_add_type"]}
                                </p>
                            </li>
                            <li
                                id="booklet-type-action-add-custom-procedure-button"
                                onClick={() =>
                                    setAddOrEditProcedure({
                                        open: true,
                                        procedure: {},
                                        parentId: item.id,
                                    })
                                }
                            >
                                <p>
                                    <Plus />
                                    {document?.data["booklets_add_procedure"]}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                {!(((item.id > 0 && item.isNew) || (item.id > 0 && item.originId > 0)) && item.parent === null) && (
                    <button
                        id="booklet-type-action-edit-button"
                        onClick={() =>
                            setAddOrEditType({
                                open: true,
                                procedure: {},
                                item: item,
                                isEdit: true,
                            })
                        }
                    >
                        <Edit /> <span>{document?.data["globals_edit"]}</span>
                    </button>
                )}
                <button id="booklet-type-action-remove-button" onClick={() => removeBookletItem(item)}>
                    <Trash2 /> <span>{document?.data["globals_delete"]}</span>
                </button>
            </div>
        );
    };

    return (
        <>
            <Breadcrumb>
                {isNew ? (
                    <Link id="breadcrumb-new-booklet-link" to={generatePath(config.routes.newBooklet[language],{ ...params })}>{document?.data["booklets_new_booklet"]}</Link>
                ) : (
                    <Link id="breadcrumb-booklet-link" to={generatePath(config.routes.booklet[language], { ...params })}>{booklet.name}</Link>
                )}
            </Breadcrumb>
            {isEdit && <Breadcrumb>{document?.data["globals_edit"]}</Breadcrumb>}
            <Content>
                <ContentHeader style={{ flexWrap: "wrap" }}>
                    <h1>{isNew ? document?.data["booklets_new_booklet"] : booklet.name}</h1>
                    <ContentActions style={{ flexWrap: "wrap" }}>{renderMenu()}</ContentActions>
                </ContentHeader>
                <Separator color="orange">{document?.data["booklets_booklet"]}</Separator>
                <ContentInfo className={styles.bookletinfo}>
                    <div className={styles.projectinfo}>
                        {booklet && booklet.id ? (
                            <>
                                <h4>{document?.data["booklets_short_desc"]}</h4>
                                <p>{booklet.description}</p>
                            </>
                        ) : (


                        <PrismicRichText
                            field={ document?.data["booklets_new_booklet_desc"]}
                        />
                        )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        {booklet.creationDate && (
                            <>
                                <p>
                                    <strong>{document?.data["booklets_creation_date"]}</strong>{" "}
                                    {new Date(booklet.creationDate).toLocaleDateString("nl-BE")}
                                </p>
                                <p>
                                    <strong>{document?.data["booklets_modification_date"]}</strong>{" "}
                                    {new Date(booklet.modificationDate).toLocaleDateString("nl-BE")}
                                </p>
                            </>
                        )}
                    </div>
                </ContentInfo>
                <ContentBody>
                    {isNew || isEdit ? (
                        <Panel>
                            <PanelHeader className={styles.header}>{renderBookletActions()}</PanelHeader>
                            <PanelBody>
                                {!booklet || !booklet.content || booklet.content.length === 0 ? (
                                    isLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Box useAccent className={[styles.box, styles.empty].join(" ")}>
                                            <p>{document?.data["booklets_no_procedures_added"]}</p>
                                            <p>{document?.data["booklets_add_using_options"]}</p>
                                        </Box>
                                    )
                                ) : (
                                    <Box className={[styles.box, styles.tree].join(" ")}>
                                        <BoxTitle className={styles.bookactions}>
                                            <Button
                                                id="booklet-entire-library-expand-button"
                                                className={styles.middle}
                                                variant="secondary"
                                                onClick={() => expandEntireLibrary()}
                                                startIcon={<ChevronDown />}
                                            >
                                                {expandLibrary
                                                    ? document?.data["booklets_collapse_all_procedures"]
                                                    : document?.data["booklets_expand_all_procedures"]}
                                            </Button>
                                            <Text
                                                type="text"
                                                style={{ margin: 0, minWidth: "300px" }}
                                                value={search}
                                                placeholder={document?.data["booklets_search"]}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    if (searchTimeout.current) clearTimeout(searchTimeout.current);

                                                    searchTimeout.current = setTimeout(() => {
                                                        setFilter(e.target.value);
                                                    }, 500);
                                                }}
                                            />
                                        </BoxTitle>
                                        <BoxBody className={styles.items}>
                                            <Tree
                                                items={booklet.content}
                                                render={(item) => renderTreeItem(item)}
                                                actions={(item) => renderActions(item)}
                                                filter={filter}
                                                search={(item, val) => {
                                                    if (
                                                        item.name &&
                                                        item.name[workingLanguage[0].value] &&
                                                        item.name[workingLanguage[0].value]
                                                            .toLowerCase()
                                                            .indexOf(val.toLowerCase()) > -1
                                                    )
                                                        return true;

                                                    if (
                                                        item.isProcedure &&
                                                        item.description &&
                                                        item.description[workingLanguage[0].value] &&
                                                        item.description[workingLanguage[0].value]
                                                            .toLowerCase()
                                                            .indexOf(val.toLowerCase()) > -1
                                                    )
                                                        return true;

                                                    if (
                                                        item.isProcedure &&
                                                        item.remarks &&
                                                        item.remarks[workingLanguage[0].value] &&
                                                        item.remarks[workingLanguage[0].value]
                                                            .toLowerCase()
                                                            .indexOf(val.toLowerCase()) > -1
                                                    )
                                                        return true;

                                                    return false;
                                                }}
                                            />
                                        </BoxBody>
                                    </Box>
                                )}
                            </PanelBody>
                        </Panel>
                    ) : (
                        <>
                            <div className={styles.preview}>
                                <ButtonGroup variant="secondary">
                                    <Button
                                        id="booklet-preview-dutch-button"
                                        className={workingLanguage[0].value === "Dutch" ? styles.activePreview : ""}
                                        onClick={() => setWorkingLanguage([workingLanguages[0]])}
                                        startIcon={<Eye />}
                                    >
                                        {document?.data["booklets_preview_dutch_version"]}
                                    </Button>
                                    <Button
                                        id="booklet-preview-french-button"
                                        className={workingLanguage[0].value === "French" ? styles.activePreview : ""}
                                        onClick={() => setWorkingLanguage([workingLanguages[1]])}
                                        startIcon={<Eye />}
                                    >
                                        {document?.data["booklets_preview_french_version"]}
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <PdfPreview booklet={booklet} />
                        </>
                    )}
                </ContentBody>
                <ContentFooter>
                    <ContentActions style={{ flexWrap: "wrap" }}>{renderMenu()}</ContentActions>
                </ContentFooter>
            </Content>

            {isAddingFromLibrary && (
                <TemplateModal
                    isOpen={isAddingFromLibrary}
                    onClose={() => setIsAddingFromLibrary(false)}
                    language={workingLanguage[0].value}
                    onAdd={(template) => onAddSelectionFromLibrary(template)}
                    booklet={booklet.content}
                />
            )}

            {addOrEditType.open && (
                <TypeModal
                    isOpen={addOrEditType.open}
                    booklet={booklet.content}
                    language={workingLanguage[0].value}
                    isEdit={addOrEditType.isEdit}
                    item={addOrEditType.item}
                    parentId={addOrEditType.parentId}
                    onSubmit={(result) => handleAddOrEditType(result)}
                    onClose={() => setAddOrEditType({ open: false })}
                />
            )}

            {addOrEditProcedure.open && (
                <ProcedureModal
                    isOpen={addOrEditProcedure.open}
                    booklet={booklet.content}
                    language={workingLanguage[0].value}
                    isEdit={addOrEditProcedure.isEdit}
                    item={addOrEditProcedure.item}
                    parentId={addOrEditProcedure.parentId}
                    onSubmit={(result) => handleAddOrEditProcedure(result)}
                    onClose={() => setAddOrEditProcedure({ open: false })}
                />
            )}

            {Boolean(isSaving) && (
                <BookletModal
                    isOpen={Boolean(isSaving)}
                    booklet={booklet}
                    onSubmit={(result) => onSaved(result)}
                    onClose={() => setIsSaving(false)}
                />
            )}

            {warning.visible && (
                <Modal open={warning.visible} backdrop onClose={warning.callback}>
                    <ModalHeader closeButton>
                        <ModalTitle>{warning.title}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>{warning.text}</ModalBody>
                    <ModalFooter>
                        <Button id="booklet-warning-cancel-button" variant="tertiary" onClick={() => setWarning({ visible: false })}>
                            {document?.data["globals_cancel"]}
                        </Button>
                        <Button id="booklet-warning-confirm-button" onClick={warning.callback}>{document?.data["globals_yes"]}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default Booklet;
