import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Radio, Select, Text , usePrismic } from "@buildwise/ui";
import { useEffect, useState } from "react";
import Periodicity from "../Periodicity/Periodicity";
import { config } from "../../_configuration/configuration";

import styles from "./ProcedureModal.module.css";

const ProcedureModal = ({ item, isEdit, booklet, parentId, language, isOpen, onSubmit, onClose }) => {
    const [canSave, setCanSave] = useState(false);

    const [dutchProcedure, setDutchProcedure] = useState("");
    const [frenchProcedure, setFrenchProcedure] = useState("");
    const [dutchRemarks, setDutchRemarks] = useState("");
    const [frenchRemarks, setFrenchRemarks] = useState("");
    const [hasPeriod, setHasPeriod] = useState(true);
    const [minPeriods, setMinPeriods] = useState([]);
    const [maxPeriods, setMaxPeriods] = useState([]);
    const [minPeriod, setMinPeriod] = useState({ period: [] });
    const [maxPeriod, setMaxPeriod] = useState({ period: [] });
    const [periodicityError, setPeriodicityError] = useState(false);
    const [parentData, setParentData] = useState([]);
    const [parent, setParent] = useState([]);
    const [action, setAction] = useState(1);

    const [document] = usePrismic(config.prismic.documentType);

    useEffect(() => setupPeriods(), []);

    useEffect(() => {
        if (isEdit) {
            setDutchProcedure(item.description.Dutch);
            setFrenchProcedure(item.description.French);
            setDutchRemarks(item.remarks?.Dutch ?? "");
            setFrenchRemarks(item.remarks?.French ?? "");
            if (item.duration) setHasPeriod(item.duration.minDurationUnit !== "P");
        } else {
            // Set empty
            setDutchProcedure("");
            setFrenchProcedure("");
            setDutchRemarks("");
            setFrenchRemarks("");
            setHasPeriod(false);
        }

        if (!booklet) return;
        parseBookletData(booklet);
    }, [isOpen, isEdit, item, booklet]);

    useEffect(() => {
        if (hasPeriod) {
            if (minPeriod.period[0] && minPeriod.period[0].value === -1) {
                setCanSave(false);
                setPeriodicityError(true);
                return;
            } else if (maxPeriod.period[0] && maxPeriod.period[0].value !== -1) {
                const minPeriodMonths = minPeriod.period[0].value === "m" ? minPeriod.value : minPeriod.value * 12;
                const maxPeriodMonths = maxPeriod.period[0].value === "m" ? maxPeriod.value : maxPeriod.value * 12;

                if (maxPeriodMonths < minPeriodMonths) {
                    setCanSave(false);
                    setPeriodicityError(true);
                    return;
                }
            }
        }

        if (dutchProcedure.trim() === "" || frenchProcedure.trim() === "" || Object.keys(parent) === 0) {
            setCanSave(false);
            return;
        }

        setPeriodicityError(false);
        setCanSave(true);
    }, [dutchProcedure, frenchProcedure, hasPeriod, minPeriod, maxPeriod, parent]);

    useEffect(() => {
        if (!minPeriods || minPeriods.length === 0) return;

        if (isEdit && item.duration) {
            const period = minPeriods.find((x) => x.value === item.duration.minDurationUnit);
            if (period) {
                setMinPeriod({ period: [period], value: item.duration.minDuration });
            } else {
                setMinPeriod({ period: [minPeriods[0]], value: 1 });
            }
        } else {
            setMinPeriod({ period: [minPeriods[0]], value: 1 });
        }
    }, [isOpen, minPeriods]);

    useEffect(() => {
        if (!maxPeriods || maxPeriods.length === 0) return;

        if (isEdit && item.duration) {
            const period = maxPeriods.find((x) => x.value === item.duration.maxDurationUnit);
            if (period) {
                setMaxPeriod({ period: [period], value: item.duration.maxDuration });
            } else {
                setMaxPeriod({ period: [maxPeriods[0]], value: 1 });
            }
        } else {
            setMaxPeriod({ period: [maxPeriods[0]], value: 1 });
        }
    }, [isOpen, maxPeriods]);

    const setupPeriods = () => {
        const periods = [
            { value: -1, label: document?.data["globals_none"] },
            { value: "m", label: document?.data["booklets_monthly"] },
            { value: "J", label: document?.data["booklets_yearly"] },
        ];

        setMinPeriods(periods.slice(1));
        setMaxPeriods(periods);
    };

    const parseBookletData = (booklet) => {
        const result = [];
        parseBookletChildren(booklet, result);
        setParentData(result);
        if (parentId) setParent([result.find((x) => x.id === parentId)]);
    };

    const parseBookletChildren = (child, result) => {
        for (let i = 0; i < child.length; i++) {
            const item = child[i];
            if (item.isProcedure === true) continue;
            result.push({
                ...item,
                label: `${item.code}. ${item.name[language]}`,
            });

            if (item.children) parseBookletChildren(item.children, result);
        }
    };

    const onSave = () => {
        let duration;
        if (hasPeriod) {
            duration = {
                minDuration: minPeriod.value,
                minDurationUnit: minPeriod.period[0].value,
            };

            if (maxPeriod.period[0] && maxPeriod.period[0].value !== -1) {
                duration = {
                    ...duration,
                    maxDuration: maxPeriod.value,
                    maxDurationUnit: maxPeriod.period[0].value,
                };
            }
        } else {
            duration = { minDurationUnit: "P" };
        }

        onSubmit &&
            onSubmit({
                parent: isEdit === true ? item.parent : parent[0],
                dutchProcedure,
                frenchProcedure,
                dutchRemarks,
                frenchRemarks,
                duration,
                isEdit: isEdit === true,
                action,
            });
    };

    return (
        <Modal open={isOpen} backdrop onClose={onClose} width="small">
            <ModalHeader closeButton>
                <ModalTitle>{isEdit ? document?.data["booklets_edit_procedure"] : document?.data["booklets_add_procedure"]}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {isEdit !== true && (
                    <>
                        <Select
                            className={styles.dropdown}
                            label={document?.data["booklets_procedure_position"]}
                            placeholder={document?.data["booklets_procedure_position"]}
                            options={parentData}
                            selection={parent}
                            onChangeSelection={(item) => setParent(item)}
                        />
                    </>
                )}

                <strong className={styles.label}>{document?.data["booklets_dutch_desc"]}</strong>
                <Text
                    type="textarea"
                    onChange={(e) => setDutchProcedure(e.target.value)}
                    value={dutchProcedure}
                    placeholder={document?.data["booklets_dutch_desc_placeholder"]}
                    required
                />

                <strong className={styles.label}>{document?.data["booklets_french_desc"]}</strong>
                <Text
                    type="textarea"
                    onChange={(e) => setFrenchProcedure(e.target.value)}
                    value={frenchProcedure}
                    required
                    placeholder={document?.data["booklets_french_desc_placeholder"]}
                />

                <div className="periodicity" style={{ paddingBottom: "8px" }}>
                    <label
                        style={{
                            fontWeight: "bold",
                            color: "#4F636D",
                            padding: "0 0 20px 16px",
                            display: "block",
                        }}
                    >
                        {document?.data["booklets_periodicity"]}
                    </label>
                    <Radio
                        label={document?.data["booklets_determine_periodicity"]}
                        name={"PeriodicityToggle"}
                        checked={hasPeriod}
                        onChange={() => setHasPeriod((x) => !x)}
                    />
                    {hasPeriod && (
                        <>
                            <Periodicity
                                periods={minPeriods}
                                period={minPeriod.period}
                                value={minPeriod.value}
                                disabled={!hasPeriod}
                                onPeriodChanged={(item) =>
                                    setMinPeriod({
                                        ...minPeriod,
                                        period: item,
                                    })
                                }
                                onValueChanged={(value) =>
                                    setMinPeriod({
                                        ...minPeriod,
                                        value: value,
                                    })
                                }
                                label={document?.data["booklets_minimum"]}
                            />
                            <Periodicity
                                periods={maxPeriods}
                                period={maxPeriod.period}
                                value={maxPeriod.value}
                                disabled={!hasPeriod}
                                onPeriodChanged={(item) =>
                                    setMaxPeriod({
                                        ...maxPeriod,
                                        period: item,
                                    })
                                }
                                onValueChanged={(value) =>
                                    setMaxPeriod({
                                        ...maxPeriod,
                                        value: value,
                                    })
                                }
                                label={document?.data["booklets_maximum"]}
                            />
                            {periodicityError && (
                                <p style={{ color: "red", margin: "5px 110px 10px 31px" }}>
                                    {document?.data["booklets_invalid_periodicity"]}
                                </p>
                            )}
                        </>
                    )}

                    <Radio
                        label={document?.data["booklets_undertermined_periodicity"]}
                        name={"PeriodicityToggle"}
                        checked={!hasPeriod}
                        onChange={() => setHasPeriod((x) => !x)}
                    />
                </div>

                <strong className={styles.label}>{document?.data["booklets_dutch_remark"]}</strong>
                <Text
                    type="textarea"
                    onChange={(e) => setDutchRemarks(e.target.value)}
                    value={dutchRemarks}
                    placeholder={document?.data["booklets_dutch_remark_placeholder"]}
                    required
                />

                <strong className={styles.label}>{document?.data["booklets_french_remark"]}</strong>
                <Text
                    type="textarea"
                    onChange={(e) => setFrenchRemarks(e.target.value)}
                    value={frenchRemarks}
                    required
                    placeholder={document?.data["booklets_french_remark_placeholder"]}
                />

                <div style={{ paddingBottom: "16px" }}>
                    <label
                        style={{
                            fontWeight: "bold",
                            color: "#4F636D",
                            padding: "0 0 20px 16px",
                            display: "block",
                        }}
                    >
                        {document?.data["booklets_question_after_saving_procedure"]}
                    </label>
                    <Radio
                        label={document?.data["booklets_add_new_procedure_to_same_type"]}
                        name={"AfterAddAction"}
                        checked={action === 0}
                        onChange={() => setAction(0)}
                    />
                    <Radio
                        label={document?.data["booklets_no_action_close_popup"]}
                        name={"AfterAddAction"}
                        checked={action === 1}
                        onChange={() => setAction(1)}
                    />
                </div>
            </ModalBody>

            <ModalFooter>
                <Button id="procedure-modal-cancel-button" variant="tertiary" onClick={() => onClose()}>
                    {document?.data["globals_cancel"]}
                </Button>
                <Button id="procedure-modal-save-button" onClick={() => onSave()} disabled={!canSave}>
                    {" "}
                    {isEdit ? document?.data["globals_edit"] : document?.data["globals_add"]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ProcedureModal;
