import { Button, Panel , usePrismic,useLanguage } from "@buildwise/ui";
import { Edit, Eye, Trash2 } from "react-feather";
import { config } from "../../_configuration/configuration";

import styles from "./BookletTable.module.css";

const BookletTable = ({ data, onView, onEdit, onDelete }) => {
    const { language } = useLanguage();

    const [document] = usePrismic(config.prismic.documentType);

    return (
        <Panel className={styles.container}>
            <div className={styles.table}>
                <div className={styles.head}>
                    <span className={styles.booklet}>{document?.data["globals_name"]}</span>
                    <span className={styles.date}>{document?.data["booklets_creation_date"]}</span>
                    <span className={styles.date}>{document?.data["booklets_modification_date"]}</span>
                    <span className={styles.actions}>{document?.data["booklets_actions"]}</span>
                </div>
                <div className={styles.body}>
                    {(!Boolean(data) || data.length === 0) && (
                        <div className={styles.row}>
                            <span className={styles.error} style={{ margin: "auto" }}>
                                {document?.data["booklets_no_results"]}
                            </span>
                        </div>
                    )}

                    {data &&
                        data.map((item, index) => {
                            const creationDate =
                                item.creationDate &&
                                new Date(item.creationDate).toLocaleDateString(language);
                            const modificationDate =
                                item.modificationDate &&
                                new Date(item.modificationDate).toLocaleDateString(language);

                            return (
                                <div key={index} className={styles.row}>
                                    <span className={styles.name}>{item.name}</span>
                                    <span className={styles.date}>
                                        <strong>{document?.data["booklets_creation_date"]}</strong>
                                        {creationDate}
                                    </span>
                                    <span className={styles.date}>
                                        <strong>{document?.data["booklets_modification_date"]}</strong>
                                        {modificationDate}
                                    </span>
                                    <span className={styles.actions}>
                                        <Button id="booklet-table-action-view-button" variant="action" startIcon={<Eye />} onClick={() => onView(item)}>
                                            {document?.data["booklets_view"]}
                                        </Button>
                                        <Button
                                            id="booklet-table-action-edit-button"
                                            variant="action"
                                            startIcon={<Edit />}
                                            onClick={() => onEdit(item)}
                                            style={{ margin: "0 10px" }}
                                        >
                                            {document?.data["globals_edit"]}
                                        </Button>
                                        <Button id="booklet-table-action-delete-button" variant="action" startIcon={<Trash2 />} onClick={() => onDelete(item)}>
                                            {document?.data["globals_delete"]}
                                        </Button>
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Panel>
    );
};

export default BookletTable;
